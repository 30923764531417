// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  AppBar,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  MuiThemeProvider,
  Toolbar,
  Typography,
  createMuiTheme,
  withStyles
} from '@material-ui/core'
import withWidth, {
  isWidthUp
} from '@material-ui/core/withWidth'
import {
  ArrowBack,
  Email,
  Home,
  Phone
} from '@material-ui/icons'
import { observer, inject } from 'mobx-react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FormattedMessage } from 'react-intl'
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// Internal
import BookPage from './BookPage'
import HomePage from './HomePage'
import ListingPage from './ListingPage'

// Assets
import LogoSPI from '../assets/Logo-SepteoADB.png'

const styles = {
  enter: {
    opacity: 0,
    position: 'absolute',
    zIndex: 99
  },
  enterActive: {
    opacity: 1,
    transition: 'opacity 250ms ease-in'
  },
  exit: {
    opacity: 1,
    position: 'absolute',
    zIndex: -99
  },
  exitActive: {
    opacity: 0,
    transition: 'opacity 250ms ease-out'
  },
  backButton: {
    marginLeft: -12,
    marginRight: 20
  }
}

@withRouter
@withStyles(styles)
@withWidth()
@inject('applicationStore')
@observer
class App extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  componentDidMount = () => {
    this.props.applicationStore.getAgenciesFromSubdomain(window.location.hostname).then(() => {
      this.props.applicationStore.getDestinationsFromAgencies()
      this.props.applicationStore.getListingsFromAgencies()
      this.props.applicationStore.getListingTypesFromAgencies()
      this.props.applicationStore.getMainAgencyLogo()
      this.props.applicationStore.getMainAgencyBackdrop()
    })
  }

  handleBackClicked = this.props.history.goBack

  render () {
    const store = this.props.applicationStore
    const mainAgency = store.agencies[store.mainAgencyId]

    if (store.mainAgencyId === '') {
      if (store.agencies.error) return <p>Error :(</p>
      return <p>Loading</p>
    } else {
      const theme = createMuiTheme({
        palette: {
          primary: {
            main: mainAgency.mainColor
          },
          secondary: {
            main: mainAgency.accentColor
          }
        },
        typography: {
          useNextVariants: true
        }
      })

      return (
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className={this.props.classes.root}>
            <AppBar>
              <Toolbar>
                {
                  this.props.location.pathname !== '/' && (
                    <>
                      {
                        this.props.history.length > 0 && (
                          <IconButton color='inherit' aria-label='Home' onClick={this.handleBackClicked}>
                            <ArrowBack />
                          </IconButton>
                        )
                      }
                      <IconButton color='inherit' aria-label='Home' component={RouterLink} to='/'>
                        <Home />
                      </IconButton>
                    </>
                  )
                }
                <img src={store.mainAgencyLogo} alt={mainAgency.name} style={{ height: '56px', paddingRight: '32px', visibility: store.mainAgencyLogo ? 'visible' : 'hidden' }} />
                {isWidthUp('sm', this.props.width) && (
                  <>
                    <Typography variant='h6' color='inherit' style={{ flexGrow: 1 }}>
                      {mainAgency.name}
                    </Typography>
                    <Typography variant='subtitle1' color='inherit'>
                      {mainAgency.emailAddress && (
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Email fontSize='inherit' />
                          &nbsp;
                          {mainAgency.emailAddress}
                        </span>
                      )}
                      {' - '}
                      {mainAgency.phone && (
                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Phone fontSize='inherit' />
                          &nbsp;
                          {parsePhoneNumberFromString(mainAgency.phone, 'FR').format(navigator.language === 'fr-FR' ? 'NATIONAL' : 'INTERNATIONAL')}
                        </span>
                      )}
                    </Typography>
                  </>
                )}
              </Toolbar>
            </AppBar>
            <div style={{ paddingTop: '64px' }}>
              <TransitionGroup>
                <CSSTransition
                  mountOnEnter unmountOnExit key={this.props.location.key} timeout={300} classNames={{
                    enter: this.props.classes.enter,
                    enterActive: this.props.classes.enterActive,
                    exit: this.props.classes.exit,
                    exitActive: this.props.classes.exitActive
                  }}
                >
                  <Switch location={this.props.location}>
                    <Route exact path='/' render={(props) => <HomePage {...props} />} />
                    <Route path='/listing/:listingId/:startDate/:endDate' render={(props) => <ListingPage {...props} />} />
                    <Route path='/listing/:listingId' render={(props) => <ListingPage {...props} />} />
                    <Route path='/book' render={(props) => <BookPage {...props} />} />
                    <Route render={() => <Redirect to='/' />} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </div>
          <footer style={{
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(8),
            padding: `${theme.spacing(6)}px 0`
          }}
          >
            <Grid container justify='space-evenly' alignItems='flex-end' style={{ width: '100%' }}>
              <Grid item>
                <Typography variant='h6' align='center' gutterBottom>
                  {mainAgency.name}
                </Typography>
                {[mainAgency.address1, mainAgency.address2, mainAgency.address3].filter(v => v !== '').map((v, i) => (
                  <Typography variant='subtitle1' align='center' color='textSecondary' key={i}>
                    {v}
                  </Typography>
                ))}
                <Typography variant='subtitle1' align='center' color='textSecondary'>
                  {mainAgency.postCode} {mainAgency.city}
                </Typography>
                <Typography variant='subtitle1' align='center' color='textSecondary'>
                  {mainAgency.emailAddress && (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Email fontSize='inherit' />
                      &nbsp;
                      {mainAgency.emailAddress}
                    </span>
                  )}
                  {' - '}
                  {mainAgency.phone && (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Phone fontSize='inherit' />
                      &nbsp;
                      {parsePhoneNumberFromString(mainAgency.phone, 'FR').format(navigator.language === 'fr-FR' ? 'NATIONAL' : 'INTERNATIONAL')}
                    </span>
                  )}
                </Typography>
                <Typography variant='subtitle1' align='center'>
                  <Link href={`https://www.homesejour.fr/login/${mainAgency.agencyId}`} target='_blank'>
                    <FormattedMessage
                      defaultMessage='Owner access'
                      id='ReservationEnLigne.Components.App.OwnerAccess'
                    />
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Link href='https://www.septeo-adb.fr/' target='_blank'>
                  <img src={LogoSPI} alt='Septeo ADB Logo' style={{ display: 'block', margin: 'auto', padding: '10px', width: '200px' }} />
                </Link>
                <Typography variant='subtitle1' align='center' color='textSecondary' gutterBottom>
                  <FormattedMessage
                    defaultMessage='© {year}, created by Septeo ADB'
                    id='ReservationEnLigne.Components.App.Copyright'
                    values={{
                      year: new Date().getFullYear()
                    }}
                  />
                </Typography>
                <Typography variant='subtitle1' align='center' color='textSecondary' gutterBottom>
                  <Link href='https://www.septeo-adb.fr/' target='_blank'>
                  https://www.septeo-adb.fr/
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </footer>
        </MuiThemeProvider>
      )
    }
  }
}

export default App
